import React, {Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HeaderTwo from "./components/header/HeaderTwo";
import MySideBar from "./components/MySideBar";

/*
* @ Component Imported
* */
import HomeDefault from "./pages/home/HomeDefault";
import HomeThreeColumn from "./pages/home/HomeThreeColumn";
import HomeFourColumn from "./pages/home/HomeFourColumn";
import HomeFiveColumn from './pages/home/HomeFiveColumn';
import HomeBlog from "./pages/home/HomeBlog";
import HomePersonalPortfolio from "./pages/home/HomePersonalPortfolio";
import HomeFreelancerPortfolio from "./pages/home/HomeFreelancerPortfolio";
import PortfolioGridTwoColumnPage from "./pages/portfolio/grid/two-column";
import PortfolioGridThreeColumnPage from "./pages/portfolio/grid/three-column";
import PortfolioGridFourColumnPage from "./pages/portfolio/grid/four-column";
import PortfolioGridThreeColumnFullWidthPage from "./pages/portfolio/grid/three-column-fullwidth";
import PortfolioGridFourColumnFullWidthPage from "./pages/portfolio/grid/four-column-fullwidth";
import PortfolioGridFiveColumnFullWidthPage from "./pages/portfolio/grid/five-column-fullwidth";
import PortfolioMasonryTwoColumnPage from "./pages/portfolio/masonry/two-column";
import PortfolioMasonryThreeColumnPage from "./pages/portfolio/masonry/three-column";
import PortfolioMasonryFourColumnPage from "./pages/portfolio/masonry/four-column";
import PortfolioMasonryThreeColumnFullWidthPage from "./pages/portfolio/masonry/three-column-fullwidth";
import PortfolioMasonryFourColumnFullWidthPage from "./pages/portfolio/masonry/four-column-fullwidth";
import PortfolioMasonryFiveColumnFullWidthPage from "./pages/portfolio/masonry/five-column-fullwidth";
import PortfolioDetailsPage from "./pages/portfolio/details";
import BlogThreeColumnPage from "./pages/blog/ThreeColumn";
import BlogTwoColumnLeftSidebarPage from "./pages/blog/TwoColumnLeftSidebar";
import BlogTwoColumnRightSidebarPage from "./pages/blog/TwoColumnRightSidebar";
import BlogDetailsRightSidebarPage from "./pages/blog/BlogDetailsRightSidebar";
import BlogDetailsLeftSidebarPage from "./pages/blog/BlogDetailsLeftSidebar";
//import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import TeamPage from "./pages/team";
import ContactPage from "./pages/contact";
import ErrorPage from "./pages/404Error";
import DemoPage from "./pages/demo";

/* My Pages */
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/MyAbout";
import ArtPortfolio from "./pages/MyArtPortfolio"

const App = () => {
    return (
        <Fragment>
            
            <Router>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`}
                           component={ContactPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                           component={AboutPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/art"}`}
                           component={ArtPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                           component={HomePage}
                    />
                    <Route exact component={ErrorPage}/>
                </Switch>
            </Router>
        </Fragment>
    );
};

export default App;