import React, {useState, useEffect} from 'react';
import portfolioData from './PortfolioData.json'
import Portfolio from "../../components/portfolio";

import {Container, Row, Col} from 'react-bootstrap';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import HeaderTwo from "../../components/header/HeaderTwo";
import MySideBar from "../../components/MySideBar";

const PortfolioGridThreeColumnPage = () => {
    const [count, setCount] = useState(21);
    const portfolios = portfolioData.slice(0, count);
    const isMobile = window.innerWidth < 992;

    useEffect(() => {
        const grid = document.querySelector('.masonry-grid');
        imagesLoaded(grid,()=>{
            new Masonry(grid, {
                itemSelector: '.masonry-grid [class*="col-"]'
            });
        });
    }, [portfolios]);

    return (
            <div className="content-body section">
                <HeaderTwo/>
                <MySideBar mobile={false}/>
                <Container fluid={!!false} className={false ? 'p-0' : null}>
                    <Row className={'row-7 portfolio-column-three masonry-grid'}>
                        {portfolios.map(portfolio => (
                            <Col key={portfolio.id} md={6} lg={4} className={'mb-15'}>
                                <Portfolio
                                    title={portfolio.title}
                                    thumb={portfolio.thumb}
                                    category={portfolio.category}
                                    variant={'column'}
                                    description={portfolio.description}
                                    meta={portfolio.meta}
                                    previewImages={portfolio.previewImages}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
    );
};

export default PortfolioGridThreeColumnPage;