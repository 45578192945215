import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";
import HeaderTwo from "../../components/header/HeaderTwo";
import MySideBar from "../../components/MySideBar";

let styles = {
    marginLeft: '0%'
};

const Contact = () => {
    return (
        <div className="bg-white section ">
            <HeaderTwo/>
            <MySideBar mobile={false}/>
            <div
                className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50"
                style={styles}>
                <Container>
                    <Row>
                        <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            <Row>
                                <Col lg={10}>
                                <div className="contact-info">
                                    <h3>CONTACT</h3>
                                    <ul>
                                        <li>
                                            <span>Phone:</span>
                                            <a href={`tel:${'+1 707 724 1475'}`}>{'+1 707 724 1475'}</a>
                                        </li>
                                        <li>
                                            <span>Email:</span>
                                            <a href={`mailto:${'jesus@blancomejia.com'}`}>{'jesus@blancomejia.com'}</a>
                                        </li>
                                    </ul>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Contact;