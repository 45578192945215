import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../../../container/MyCustomPage";
import PortfolioDetailsThumb from "./thumbnail";
import PortfolioDetailsContent from "./content";
import portfolioDetailsData from '../../../data/portfolio/details'


const MyTemplatePortfolioDetails = ({title, thumb, category, description, meta, previewImages}) => {
    return (
        <PageContainer classes={'bg-white'}>
            {console.log("Portfolio loaded: ", title, thumb, category)}
            <Container>
                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb images={previewImages}/>
                    </Col>

                    <Col lg={5}>
                        <PortfolioDetailsContent
                            title={title}
                            category={category}
                            description={description}
                            meta={meta}
                        />
                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

export default MyTemplatePortfolioDetails;