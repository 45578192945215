import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import {Button} from 'react-bootstrap';
import PortfolioDetails from "../../templates/MyPortfolio/details";


const Portfolio = ({thumb, classes, title, category, variant, description, meta, previewImages}) => {

    /* Modal */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div>
            <Modal 
                show={show} 
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Body>
                    <PortfolioDetails
                        title={title}
                        thumb={thumb}
                        category={category}
                        description={description}
                        meta={meta}
                        previewImages={previewImages}
                    >
                    </PortfolioDetails>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className={`portfolio-item ${classes ? classes : ' '}`} onClick={handleShow}>
                    {variant === 'column' ? (
                        <div className="portfolio-image">
                            <img src={require('../../assets/images/' + thumb)} alt={title}/>
                        </div>

                    ) : (
                        <div className="portfolio-image"
                            style={{backgroundImage: `url(${require('../../assets/images/' + thumb)})`}}/>
                    )}
                    <div className="portfolio-content">
                        <h4 className="title">{title}</h4>
                        <div className="category"><span>{category}</span></div>
                    </div>
            </div>
        </div>
    );
};

export default Portfolio;
