import React from 'react';

const MyPageContainer = ({children, classes, fullWidth}) => {

    const isMobile = window.innerWidth < 992;

    return (
        <div className={`content-body section ${classes}`}>
            <div style={{display: 'flex', width: '100%', maxHeight: isMobile ? 'auto' : 'calc(100vh - 200px)'}}>
                    <div className={`section-wrap section ${!fullWidth ? 'section-padding' : null}`}>
                        {children}
                    </div>
            </div>
        </div>
    );
};

export default MyPageContainer;