import React from 'react';
import PageContainer from "../../container/CustomPage";
import {Link} from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
import aboutData from './AboutData.json'
import HeaderTwo from "../../components/header/HeaderTwo";
import MySideBar from "../../components/MySideBar";

const ContentAboutPage = () => {
    return (
        <PageContainer classes={'bg-white'}>
            <HeaderTwo/>
            <MySideBar mobile={false}/>
           <Container>
                <Row className={'align-items-center'}>
                    <Col lg={6}>
                        <Thumbnail classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30'} thumb={`about/${aboutData.thumb}`}/>
                    </Col>

                    <Col lg={6}>
                        <Content classes={'about-content'}>
                            <h3 className="block-title">About Me</h3>
                            <p>{aboutData.bio}</p>
                            <ul className="personal-info">
                                <li><span>Education:</span>{aboutData.education}</li>
                                <li><span>Degree:</span>{aboutData.degree}</li>
                                <li>
                                    <span>Languages:</span>
                                    {aboutData.languages.map((language, index) => (
                                        <p key={index} style={{display: 'inline-block'}}>{`${language}`}</p>
                                    ))}
                                </li>
                            </ul>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

export default ContentAboutPage;