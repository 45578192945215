import React from 'react';
import { useHistory } from "react-router-dom";

// File imported
import HeaderTwo from "../../components/header/HeaderTwo";
import MySideBar from "../../components/MySideBar";
import {Image, Row, Col} from 'react-bootstrap';
import logo from "../../assets/images/logo.png";
//import ContentHomeBlogPage from "../../templates/HomeBlog";

const HomePage = () => {
    const history = useHistory();

    async function handleClick() {
        history.push("/about");
    }
    return (
        <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "600px"
        }}>
            <a href={`${process.env.PUBLIC_URL + "/about"}`}>
                <Image src={logo} onClick={handleClick}></Image>
            </a>
        </div>
    );
};

export default HomePage;
